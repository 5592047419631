'use client';
import { LiveKitRoom, VideoConference } from '@livekit/components-react';
import { ExternalE2EEKeyProvider, Room, RoomOptions, VideoCodec, VideoPresets } from 'livekit-client';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

function decodePassphrase(base64String: string) {
  return decodeURIComponent(base64String);
}

export default function CustomRoomConnection() {
  const router = useRouter();
  const { token, codec, callback } = router.query;
  const [tokenState, setTokenState] = useState<string | undefined>(undefined);
  const [parametersLoaded, setParametersLoaded] = useState(false);

  useEffect(() => {
    if (typeof token === 'string') {
      setTokenState(token);
    }

    setParametersLoaded(true);
  }, [token]);

  const url = 'wss://webrtc.654333.xyz';
  const e2eePassphrase =
    typeof window !== 'undefined' && decodePassphrase(window.location.hash.substring(1));
  const worker =
    typeof window !== 'undefined' &&
    new Worker(new URL('livekit-client/e2ee-worker', import.meta.url));
  const keyProvider = new ExternalE2EEKeyProvider();

  const e2eeEnabled = !!(e2eePassphrase && worker);

  const roomOptions = useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216],
        red: !e2eeEnabled,
        videoCodec: codec as VideoCodec | undefined,
      },
      adaptiveStream: false,
      dynacast: true,
      e2ee: e2eeEnabled
        ? {
          keyProvider,
          worker,
        }
        : undefined,
    };
  }, []);

  const room = useMemo(() => new Room(roomOptions), []);
  if (e2eeEnabled) {
    keyProvider.setKey(e2eePassphrase);
    room.setE2EEEnabled(true);
  }

  let cb = 'https://new.oaifree.com';
  if (typeof callback === 'string' && callback.startsWith('http')) {
    cb = callback;
  }
  const onLeave = React.useCallback(() => router.push(cb), [cb]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = new URL(window.location.href);
      url.searchParams.set('token', 'undefined');
      url.hash = '';
      window.history.replaceState({}, document.title, url.toString());
    }
  }, []);

  if (!parametersLoaded) {
    return (
      <main data-lk-theme="default">
        <div>Loading...</div>
      </main>
    );
  }

  if (!url || typeof tokenState !== 'string') {
    return (
      <main data-lk-theme="default">
        <div><a href={cb}>Go back</a></div>
      </main>
    );
  }

  return (
    <main data-lk-theme="default">
      {url && tokenState && (
        <LiveKitRoom
          room={room}
          token={tokenState}
          serverUrl={url}
          audio={true}
          video={false}
          screen={false}
          onDisconnected={onLeave}
        >
          <VideoConference />
        </LiveKitRoom>
      )}
    </main>
  );
}
